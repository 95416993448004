/**
 * La classe `DoceoToggleSelector` permet de basculer l'affichage des éléments cibles
 * en fonction de la valeur sélectionnée dans un élément `<select>`.
 */
export class DoceoToggleSelector {
    /** 
     * Élément `<select>` utilisé pour déclencher la sélection et le changement d'affichage.
     */
    private doceoSelectToggleElement: HTMLSelectElement;

    /**
     * Crée une instance de `DoceoToggleSelector` et initialise la gestion du toggle des éléments cibles.
     * @param doceoSelectToggleElement - Élément HTML `<select>` pour la sélection des options.
     */
    constructor(doceoSelectToggleElement: HTMLSelectElement) {
        this.doceoSelectToggleElement = doceoSelectToggleElement;
        this.init();
    }

    /**
     * Initialise le comportement de basculement d'affichage en fonction de la valeur de l'élément `<select>`.
     * @remarks
     * - Les éléments à basculer doivent contenir l'attribut `data-toggle-selector-value` pour être pris en compte.
     * - L'attribut `data-toggle-target` permet de définir la portée de la recherche des éléments à afficher ou masquer.
     */
    private init() {
        // Définir la cible sur l'élément défini par `data-toggle-target` ou sur `document` par défaut.
        const target = (this.doceoSelectToggleElement.dataset.toggleTarget ? document.querySelector(this.doceoSelectToggleElement.dataset.toggleTarget) : null) || document;

        // Création d'un tableau d'objets contenant les éléments cibles et leur conteneur parent.
        const stock = Array.from(target.querySelectorAll<HTMLElement>(`[data-toggle-selector-value]`)).map(el => {
            return {
                element: el,
                container: el.parentElement
            };
        });

        // Fonction pour masquer tous les éléments cibles
        const clear = () => {
            stock.forEach(item => {
                item.element.classList.add('d-none');
                item.element.remove();
            });
        };
        clear();

        // Fonction pour afficher l'élément correspondant à la valeur sélectionnée
        const update = () => {
            clear();

            // Filtrer et afficher les éléments dont la valeur correspond à celle du `select`
            stock.filter(item => {
                return item.element.dataset.toggleSelectorValue === this.doceoSelectToggleElement.value;
            }).forEach(item => {
                item.element.classList.remove('d-none');
                item.container?.appendChild(item.element);
            });
        };

        // Si une valeur est déjà sélectionnée, appliquer le toggle initial
        if (this.doceoSelectToggleElement.value) {
            update();
        }

        // Mettre à jour l'affichage lors du changement de la valeur du `<select>`
        this.doceoSelectToggleElement.addEventListener('change', () => {
            update();
        });
    }
}
