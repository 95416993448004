/** 
 * La classe `DoceoRing` permet de gérer un élément HTML en surveillant les mutations 
 * des classes CSS et en mettant à jour les étapes associées dans l'élément "ring".
 */
export class DoceoRing {
    /** 
     * Observateur de mutations utilisé pour surveiller les changements d'attributs 
     * sur les éléments enfants, notamment pour les classes CSS.
     */
    private mutationObserver = new MutationObserver((mutationsList) => {
        for (const mutation of mutationsList) {
            if (mutation.attributeName === "class") {
                // Désactiver l'observation avant d'appeler updateSteps pour éviter une boucle
                this.mutationObserver.disconnect();
                this.updateSteps();
                // Réactiver l'observation après les modifications
                this.mutationObserver.observe(this.ringElement, {
                    attributes: true,
                    subtree: true
                });
            }
        }
    });

    /** 
     * Élément HTML principal qui contient les étapes du `DoceoRing`.
     */
    private ringElement: HTMLElement;

    /**
     * Crée une instance de la classe `DoceoRing` et initialise l'observateur de mutations.
     * @param ringElement - Élément HTML représentant le ring.
     */
    constructor(ringElement: HTMLElement) {
        this.ringElement = ringElement;
        this.init();
    }

    /** 
     * Sélecteur pour récupérer l'élément contenant les étapes du `DoceoRing`.
     */
    private get stepContainerElement() {
        return this.ringElement.querySelector<HTMLElement>('.doceo-ring-steps');
    }

    /** 
     * Sélecteur pour récupérer tous les éléments représentant les étapes individuelles.
     */
    private get steps() {
        return this.stepContainerElement?.querySelectorAll<HTMLElement>('.doceo-ring-steps-step');
    }

    /** 
     * Initialise le `DoceoRing` en mettant à jour les étapes et en configurant l'observateur 
     * de mutations pour détecter les changements de classes sur les éléments.
     */
    private init() {
        this.updateSteps();

        if (this.stepContainerElement) {
            this.mutationObserver.observe(this.stepContainerElement, {
                attributes: true,
                subtree: true
            });
        }
    }

    /** 
     * Met à jour les étapes du `DoceoRing` en ajoutant ou supprimant la classe `next-is-active` 
     * en fonction de l'état des éléments suivants dans la liste des étapes.
     */
    private updateSteps() {
        this.steps?.forEach(step => {
            const nextStepElement = step.nextElementSibling;

            if (nextStepElement && nextStepElement.classList.contains('active')) {
                step.classList.add('next-is-active');
            } else {
                step.classList.remove('next-is-active');
            }

        });
    }
}
