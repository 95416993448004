import { EsCollapse } from "evostrap";

/**
 * La classe `DoceoAccordion` gère un accordéon basé sur des éléments HTML,
 * en utilisant des événements liés au composant `EsCollapse` pour afficher ou masquer des éléments.
 */
export class DoceoAccordion {
    /** 
     * Élément HTML principal qui contient les items de l'accordéon.
     */
    private accordionElement: HTMLElement;

    /**
     * Crée une instance de la classe `DoceoAccordion` et initialise le comportement de l'accordéon.
     * @param accordionElement - Élément HTML représentant l'accordéon.
     */
    constructor(accordionElement: HTMLElement) {
        this.accordionElement = accordionElement;
        this.init();
    }

    /**
     * Initialise les items de l'accordéon en ajoutant des écouteurs d'événements 
     * sur les éléments `EsCollapse` pour gérer l'affichage, la suppression des classes CSS 
     * et le défilement automatique.
     */
    private init() {
        const accordionItems = this.accordionElement.querySelectorAll<HTMLElement>('.es_accordion-item');

        accordionItems.forEach(accordionItem => {
            const collapseElement = accordionItem.querySelector<EsCollapse.EsCollapseElement>('.es_accordion-item-content');

            // Ajoute des classes lorsqu'un élément est en cours d'affichage
            collapseElement?.addEventListener('show.es.collapse', e => {
                accordionItem.classList.add('border-primary', 'position-relative');
            });

            // Retire les classes lorsqu'un élément est masqué
            collapseElement?.addEventListener('hide.es.collapse', e => {
                accordionItem.classList.remove('border-primary', 'position-relative');
            });

            // Défilement automatique vers l'élément lorsque celui-ci est complètement affiché
            collapseElement?.addEventListener('shown.es.collapse', e => {
                const targetTop = accordionItem.offsetTop - 40;

                window.scrollTo({ behavior: "smooth", top: targetTop });
            });
        });
    }
}
