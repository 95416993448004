import { DoceoAccordion } from "./inc/doceo-accordion";
import { DoceoRing } from "./inc/doceo-ring";
import { DoceoToggleSelector } from "./inc/doceo-toggle-selector";

/**
 * Initialisation des composants lorsque le document est chargé et prêt.
 */
document.addEventListener('DOMContentLoaded', () => {
    document.querySelectorAll<HTMLElement>('.es_accordion').forEach(accordionElement => {
        new DoceoAccordion(accordionElement);
    });

    document.querySelectorAll<HTMLSelectElement>('select.doceo-toggle-selector').forEach(selectElement => {
        new DoceoToggleSelector(selectElement);
    });

    document.querySelectorAll<HTMLElement>('.doceo-ring').forEach(ringElement => {
        new DoceoRing(ringElement);
    });
});